import React from "react";

import "./footer.scss"

export default function Footer() {
    return (
        // TODO footer doesnt work. it is adding too much spacing on the right
        <span>
            Copyright &copy; Frontangle.com
        </span>
    );
}
